import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../app/auth/service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService, private _userService: UserService
    ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {    
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

    /**
   *  Confirms if user is client
   */
     get isUser() {
      return this.currentUser && this.currentUserSubject.value.role === Role.User;
    }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/auth/login`, { email, password })
      .pipe(
        map(user => {              
          // login successful if there's a jwt token in the response
            this.authenticateUser(user);
        })
      );
  }

  authenticateUser(user) {
    if (user.auth && user.auth.token) {
            
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('currentUser', JSON.stringify(user.auth));
      localStorage.setItem('token', user.auth.token);
      // Display welcome toast!
      setTimeout(() => {
        this._toastrService.success(
          'You have successfully logged in as an ' +
            user.auth.role +
            ' user to SmilePass. Now you can start to explore. Enjoy! 🎉',
          '👋 Welcome, ' + user.auth.firstName + '!',
          { toastClass: 'toast ngx-toastr', closeButton: true }
        );
      }, 2500);

      // notify
      this.currentUserSubject.next(user.auth);
    }
    return user.auth;
  }
    /**
 * Get all users
 */
    authMe(): Observable<User[]> {
      return this._http.get<User[]>(`${environment.apiUrl}/auth/me`)
    }

        /**
   * Get locations by customer id 
   */
        setLocation(locationId) {
          return new Promise((resolve, reject) => {            
            this._http.put(`${environment.apiUrl}/user/${locationId}`, null).subscribe((response: any) => {
              resolve(response);
              this.authMe().subscribe((user: any) => {
                this.currentUserSubject.next(user);
                localStorage.setItem("currentUser", JSON.stringify(user));
                // console.log(this.currentUser);
                const index = user.location.findIndex(object => {
                  return object.id === user.sessionLocation;
                });
                setTimeout(() => {
                  this._toastrService.success(
                    'You have successfully change your location to ' +
                    user['location'][index].locationName + '! 🎉',
                    '👋 Welcome to ' + user['location'][index].locationName + '!',
                    { toastClass: 'toast ngx-toastr', closeButton: true }
                  );
                }, 2500);
  
                resolve(user);
                return user;
              }, reject);;
              return response;

            }, reject);       
          });
        }
  


  getToken(): string | null {
    return localStorage.getItem('token'); // Get token from local storage
  }

  /**
   * Change the password
   *
   * @param oldPassword
   * @param newPassword
   * @param retypeNewPassword
   */
  changePassword(oldPassword: string, newPassword: string, retypeNewPassword: string) {
    var currentUser = JSON.parse(localStorage.getItem('currentUser'))    

    return this._http
      .post<any>(`${environment.apiUrl}/auth/password`, { oldPassword, newPassword, retypeNewPassword })
      .pipe(
        map(user => {
          // Change successful
            // Display confimation toast!
            this._toastrService.success(
              currentUser.firstName +' have successfully changed your password. 🎉',
                'Success! New password saved.',
              { toastClass: 'toast ngx-toastr', closeButton: true }
            );
          }
        )
      );
  }

    /**
   * User SignUp
   *
   * @param firstName
   * @param lastName
   * @param email
   * @param password
   * @returns user
   */
     signUp(firstName: string, lastName: string, email: string, password: string) {
      return this._http
        .post<any>(`${environment.apiUrl}/auth/signup`, { firstName, lastName, email, password })
        .pipe(
          map(user => {
            // login successful if there's a jwt token in the response
            if (user && user.token) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('currentUser', JSON.stringify(user));
  
              // Display welcome toast!
              setTimeout(() => {
                this._toastrService.success(
                  'You have successfully logged in as an ' +
                    user.role +
                    ' user to Vuexy. Now you can start to explore. Enjoy! 🎉',
                  '👋 Welcome, ' + user.firstName + '!',
                  { toastClass: 'toast ngx-toastr', closeButton: true }
                );
              }, 2500);
  
              // notify
              this.currentUserSubject.next(user);
            }
  
            return user;
          })
        );
    }

  

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }

  forgotPassword(email, returnLink){
    return this._http
    .patch<any>(`${environment.apiUrl}/user/${email}/forgot_password`,  {"returnLink":returnLink} )
    .pipe(
      map(user => {
        // Change successful
          // Display confimation toast!
          this._toastrService.success(
           'You have successfully requested to change your password. 🎉',
              'Success! Request new password.',
            { toastClass: 'toast ngx-toastr', closeButton: true }
          );
        }
      )
    );

  };

    /**
   * Change the password
   *
   * @param oldPassword
   * @param newPassword
   * @param retypeNewPassword
   */
    resetPassword(token: string, newPassword: string) {
  
      return this._http
        .patch<any>(`${environment.apiUrl}/user/${token}/reset_password`, { 'newPassword':newPassword })
        .pipe(
          map(user => {
            // Change successful
              // Display confimation toast!
              this._toastrService.success(
                'You have successfully changed your password. 🎉',
                  'Success! New password saved.',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }
          )
        );
    }
}
