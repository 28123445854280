import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SharedLocationService {

  constructor(private _httpClient: HttpClient) { }

  locationChanged: EventEmitter<number> = new EventEmitter<number>();

  emitLocationChange(locationId: number) {
    this.locationChanged.emit(locationId);
  }

  getLocationChangeEmitter() {
    return this.locationChanged;
  }

  getlocationCache(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
          .get(`${environment.apiUrl}/user/location/cache`)
          .subscribe((response: any) => {  
        resolve(response);
      }, reject);        
    });
  };
  
}

