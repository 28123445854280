import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { User } from 'app/auth/models';


@Injectable({ providedIn: 'root' })
export class UserService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {}

  /**
   * Get all users
   */
  getAll(): Observable<User[]> {
    return this._http.get<User[]>(`${environment.apiUrl}/user`);
  };

  /**
 * Get all users
 */
  authMe(): Observable<User[]> {
    return this._http.get<User[]>(`${environment.apiUrl}/auth/me`);
  };

  /**
   * Get user by id
   */
  getById(id: number) {
    return this._http.get<User>(`${environment.apiUrl}/user/${id}`);
  };

    /**
 * Create new user
 *
 * @param firstName
 * @param lastName
 * @param email
 * @param role
 * @param phone
 * @param password
 * @param retypePassword
 * @param userAccessLocation
 * @returns user
 */
  createNewUser( firstName: string, lastName: string, email: string, role: string, userAccessLocation: string ) {
    var password = "fsdfEf$@%sd68@4";
    var retypePassword = "fsdfEf$@%sd68@4";
    return new Promise((resolve, reject) => { this._http
      .post<any>(`${environment.apiUrl}/user`, { firstName, lastName, email, role, password, retypePassword, userAccessLocation })
      .subscribe((response: any) => {
        resolve(response);
      }, reject);       
    })
  };

  createNewUserFirstAccess( firstName: string, lastName: string, email: string, timeZone) {
    return new Promise((resolve, reject) => { this._http
      .post<any>(`${environment.apiUrl}/lead`, { firstName, lastName, email, origin: 'website', timeZone})
      .subscribe((response: any) => {
        resolve(response);
      }, reject);       
    })
  };

  validateFirstAccess( email: string, password: string, code: any, ) {
    return new Promise((resolve, reject) => { this._http
      .post<any>(`${environment.apiUrl}/lead/validate`, { email, password, code})
      .subscribe((response: any) => {
        resolve(response);
      }, reject);       
    })
  };

  updateOnboardingInfo( info ) {
    return new Promise((resolve, reject) => { this._http
      .patch<any>(`${environment.apiUrl}/lead/onboarding`, info)
      .subscribe((response: any) => {
        resolve(response);
      }, reject);       
    })
  };

    /**
   * Update my profile
   *
   * @param user_id
   * @param firstName
   * @param lastName
   * @param email
   * @param phone
   * @returns user
   */
     updateMyProfile(user_id: number, firstName: string, lastName: string, email: string, phoneNumber: string, avatar: string ) {
      return new Promise((resolve, reject) => { this._http
        .patch<any>(`${environment.apiUrl}/user/${user_id}`, { firstName, lastName, email, phoneNumber, avatar })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);       
      })
    };

      /**
   * Update user
   *
   * @param user_id
   * @param location_id
   * @returns location
   */
      updateUserLocation(location_id: number, user_id:string) {
        return new Promise((resolve, reject) => { this._http
          .put<any>(`${environment.apiUrl}/user/${user_id}/location`, { location_id })
          .subscribe((response: any) => {
            resolve(response);
          }, reject);       
      })
    };

      /**
   * Update my profile
   *
   * @param user_id
   * @param role
   * @returns user
   */
      changeRole(user_id: number, role: string) {
        return new Promise((resolve, reject) => { this._http
          .patch<any>(`${environment.apiUrl}/user/${user_id}`, { role })
          .subscribe((response: any) => {
            resolve(response);
          }, reject);       
      })
    };

          /**
   * Update my profile
   *
   * @param status
   * @returns user
   */
    changeStatus(user_id: number, status: string) {
      return new Promise((resolve, reject) => { this._http
        .patch<any>(`${environment.apiUrl}/user/${user_id}`, { status })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);       
      })
    };
  

        /**
   * Change password
   *
   * @param newPassword
   * @param retypeNewPassword
   */
    newPassword(newPassword: string, retypeNewPassword: string) {
        return new Promise((resolve, reject) => { this._http
          .post<any>(`${environment.apiUrl}/auth/password`, { newPassword, retypeNewPassword })
          .subscribe((response: any) => {
            resolve(response);
          }, reject);       
      })
    };
  

  


}