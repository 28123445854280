import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from "../../../../environments/environment";
import Swal from "sweetalert2";


@Injectable({
  providedIn: 'root'
})
export class EcommerceService implements Resolve<any> {
  // Public
  public productList: Array<any>;
  public wishlist: Array<any>;
  public cartList: Array<any>;
  public selectedProduct;
  public relatedProducts;

  public onProductListChange: BehaviorSubject<any>;
  public onRelatedProductsChange: BehaviorSubject<any>;
  public onWishlistChange: BehaviorSubject<any>;
  public onCartListChange: BehaviorSubject<any>;
  public onSelectedProductChange: BehaviorSubject<any>;

  // Private
  private idHandel;

  private sortRef = key => (a, b) => {
    const fieldA = a[key];
    const fieldB = b[key];

    let comparison = 0;
    if (fieldA > fieldB) {
      comparison = 1;
    } else if (fieldA < fieldB) {
      comparison = -1;
    }
    return comparison;
  };

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    this.onProductListChange = new BehaviorSubject({});
    this.onRelatedProductsChange = new BehaviorSubject({});
    this.onWishlistChange = new BehaviorSubject({});
    this.onCartListChange = new BehaviorSubject({});
    this.onSelectedProductChange = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.idHandel = route.params.id;

    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getProducts(), this.getWishlist(), this.getCartList(), this.getSelectedProduct()]).then(() => {
        resolve();
      }, reject);
    });
  }

  /**
   * Get Products
   */
  getProducts(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/store`).subscribe((response: any) => {
        this.productList = response;
        this.sortProduct('featured'); // Default shorting
        resolve(this.productList);
      }, reject);
    });
  }

  /**
   * Get Wishlist
   */
  getWishlist(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('api/ecommerce-userWishlist').subscribe((response: any) => {
        this.wishlist = response;
        this.onWishlistChange.next(this.wishlist);
        resolve(this.wishlist);
      }, reject);
    });
  }

  /**
   * Get CartList
   */
  getCartList(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('api/ecommerce-userCart').subscribe((response: any) => {
        this.cartList = response;

        this.onCartListChange.next(this.cartList);
        resolve(this.cartList);
      }, reject);
    });
  }

  /**
   * Get Selected Product
   */
  getSelectedProduct(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('api/ecommerce-products?id=' + this.idHandel).subscribe((response: any) => {
        this.selectedProduct = response;
        this.onSelectedProductChange.next(this.selectedProduct);
        resolve(this.selectedProduct);
      }, reject);
    });
  }

  /**
   * Get Related Products
   */
  getRelatedProducts(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('api/ecommerce-relatedProducts').subscribe((response: any) => {
        this.relatedProducts = response;
        this.onRelatedProductsChange.next(this.relatedProducts);
        resolve(this.relatedProducts);
      }, reject);
    });
  }

  /**
   * Sort Product
   *
   * @param sortBy
   */
  sortProduct(sortBy) {
    let sortDesc = false;

    const sortByKey = (() => {
      if (sortBy === 'price-desc') {
        sortDesc = true;
        return 'price';
      }
      if (sortBy === 'price-asc') {
        return 'price';
      }
      sortDesc = true;
      return 'id';
    })();

    const sortedData = this.productList.sort(this.sortRef(sortByKey));
    if (sortDesc) sortedData.reverse();
    this.productList = sortedData;
    this.onProductListChange.next(this.productList);
  }

  /**
   * Add In Wishlist
   *
   * @param id
   */
  addToWishlist(id) {
    return new Promise<void>((resolve, reject) => {
      const lengthRef = this.wishlist.length + 1;
      const wishRef = { id: lengthRef, productId: id };

      this._httpClient.post('api/ecommerce-userWishlist/' + lengthRef, { ...wishRef }).subscribe(response => {
        this.getWishlist();
        resolve();
      }, reject);
    });
  }

  /**
   * Remove From Wishlist
   *
   * @param id
   */
  removeFromWishlist(id) {
    const indexRef = this.wishlist.findIndex(wishlistRef => wishlistRef.productId === id); // Get the index ref
    const indexId = this.wishlist[indexRef].id; // Get the product wishlist id from indexRef
    return new Promise<void>((resolve, reject) => {
      this._httpClient.delete('api/ecommerce-userWishlist/' + indexId).subscribe((response: any) => {
        this.getWishlist();
        resolve();
      }, reject);
    });
  }

  /**
   * Add In Cart
   *
   * @param id
   */
  addToCart(id) {
    return new Promise<void>((resolve, reject) => {
      const maxValueId = Math.max(...this.cartList.map(cart => cart.id), 0) + 1;
      const cartRef = { id: maxValueId, productId: id, qty: 1 };
      var cartId: any = '';

      // If cart is not Empty
      if (maxValueId !== 1) {
        cartId = maxValueId;
      }
      this._httpClient.post('api/ecommerce-userCart/' + cartId, { ...cartRef }).subscribe(response => {
        this.getCartList();
        resolve();
      }, reject);
    });
  }

  /**
   * Remove From Cart
   *
   * @param id
   */
  removeFromCart(id) {
    const indexRef = this.cartList.findIndex(cartListRef => cartListRef.productId === id); // Get the index ref
    const indexId = this.cartList[indexRef].id; // Get the product wishlist id from indexRef

    return new Promise<void>((resolve, reject) => {
      this._httpClient.delete('api/ecommerce-userCart/' + indexId).subscribe((response: any) => {
        this.getCartList();
        resolve();
      }, reject);
    });
  }

  viewPdf(id): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/store/download-pdf/${id}`).subscribe((response: any) => {
        window.open(response, '_blank', 'width=800,height=600,scrollbars=yes');
        resolve(response);
      }, reject);
    });
  }

  requestOrder(product): Promise<any[]> {
    return new Promise((resolve, reject) => {
      var currentUser = JSON.parse(localStorage.getItem("currentUser"));
      var delivery_to = "";

      if (product.category == 'In-Store') {
        delivery_to = currentUser.currentLocation.locationAddress;
        Swal.mixin({
          input: 'text',
          confirmButtonText: 'Next &rarr;',
          showCancelButton: true,
          progressSteps: ['1', '2', '3'],
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
          }
        })
          .queue([
            {
              title: 'Quantity',
              text: 'Enter the quantity of the product:',
              input: 'number',
              inputAttributes: {
                min: '1',
                max: '500',
                step: '1'
              },
              showCancelButton: true,
              confirmButtonText: 'Next',
            },
            {
              title: 'Delivery Address',
              text: 'Enter the delivery address:',
              input: 'text',
              inputPlaceholder: 'Type the full delivery address',
              inputValue: delivery_to,
              showCancelButton: true,
              confirmButtonText: 'Next',
            },
            {
              text: 'Any applicable costs can be deducted from your outstanding balance. Do you agree?',
              input: 'radio',
              inputValue: 'false',
              inputOptions: { true: 'Yes', false: 'No' },
              showCancelButton: true,
              confirmButtonText: 'Send',
            },
          ])
          .then((result:any) => {
            if(result.value){
              var order = {"quantity": result.value[0],"address":result.value[1], "deducted": result.value[2]};
              
              this._httpClient.post(`${environment.apiUrl}/store/request/${product.id}`, {"order":order} )
              .subscribe((response: any) => {
                resolve(response);
              }, reject);


              Swal.fire({
                title: 'All done!',
                text: 'We have received your request, and someone from our team will arrange the shipment of the requested materials.',

                confirmButtonText: 'OK',
                customClass: { confirmButton: 'btn btn-primary' }
              });
            };
            
          });
      }else{
        delivery_to = currentUser.currentLocation.locationEmail;
        Swal.mixin({
          input: 'text',
          confirmButtonText: 'Next &rarr;',
          showCancelButton: true,
          progressSteps: ['1'],
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
          }
        })
          .queue([
            {
              text: 'Any applicable costs can be deducted from your outstanding balance. Do you agree?',
              input: 'radio',
              inputValue: 'false',
              inputOptions: ['Yes','No'],

              showCancelButton: true,
              confirmButtonText: 'Send',
            },
          ])
          .then((result:any) => {
            if(result.value){
              var order = {"quantity": result.value[0],"address":result.value[1], "deducted": result.value[2]};
              
              this._httpClient.post(`${environment.apiUrl}/store/request/${product.id}`, order )
              .subscribe(
                (response: any) => {
                  Swal.fire({
                    title: 'All done!',
                    text: 'We have received your request. For digital orders, the requested materials will arrive in your email shortly.',
                    confirmButtonText: 'OK',
                    customClass: { confirmButton: 'btn btn-primary' },
                  });
                },
                (error) => {
                  console.error(error);
                }
              );

      }
      


    
  })
}})}

  sendOrder (product, order): Promise<any[]> {
    return new Promise((resolve, reject) => {
    this._httpClient.post(`${environment.apiUrl}/store/request/${product.id}`, order )
    .subscribe((response: any) => {
      resolve(response);
    }, reject);
    })
  }

}
