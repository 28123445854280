<li class="nav-item">
  <a class="nav-link nav-link-style btn" (click)="openSender(modalLG)">
    <span [data-feather]="'user-plus'" [class]="'ficon'"></span>
  </a>
</li>
  <!-- Modal -->
  <ng-template #modalLG let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="myModalLabel1">Register a New Patient</h4>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus style="height: 400px; overflow-y: scroll;">
      <!-- Select Location -->
      <div class="form-group col-12 align-items-center" style="text-align: -webkit-center;">
        <label class="form-label" for="location">Current Location:</label>
        <select class="form-control w-50" id="location" disabled style="font-size: 14px; height: 40px;">
          <option >{{currentLocationName}}</option>
        </select>
      </div>
      <!-- / Select Location -->
      <form action="#" #newInviteForm="ngForm">
        <div class="row">
  
          
  
          <!-- First Name -->
          <div class="form-group col-6">
            <label class="form-label" for="navbar-invite-firstname">First Name</label>
            <input
              type="text"
              class="form-control dt-first-name"
              id="navbar-invite-firstname"
              placeholder="John"
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="inviteContent['firstName']"
              [value]=""
              [class.error]="newInviteForm.submitted && FFirstNameRef.invalid"
              #FFirstNameRef="ngModel"
              tabindex="1"
              required
              aria-label="John"
              aria-describedby="navbar-invite-firstname2"
            />
            <span *ngIf="newInviteForm.submitted && FFirstNameRef.invalid" class="invalid-form">
              <small class="form-text text-danger" *ngIf="FFirstNameRef.errors.required">This field is required!</small>
            </span>
          </div>
          <!-- / First Name -->
  
          <!-- Last Name -->
          <div class="form-group col-6">
            <label class="form-label" for="navbar-invite-lastname">Last Name</label>
            <input
              type="text"
              id="navbar-invite-lastname"
              class="form-control dt-lastname"
              placeholder="Doe"
              aria-label="Doe"
              aria-describedby="navbar-invite-lastname2"
              name="user-lastname"
              [(ngModel)]="inviteContent['lastName']"
              [class.error]="newInviteForm.submitted && FLastNameRef.invalid"
              #FLastNameRef="ngModel"
              tabindex="2"
              required
            />
            <span *ngIf="newInviteForm.submitted && FLastNameRef.invalid" class="invalid-form">
              <small class="form-text text-danger" *ngIf="FLastNameRef.errors.required">This field is required!</small>
            </span>
          </div>
          <!-- / Last Name -->
          
          <!-- Email -->
          <div class="form-group col-6">
            <label class="form-label" for="navbar-invite-email">Email</label>
            <input
              type="text"
              id="navbar-invite-email"
              class="form-control dt-email"
              placeholder="john.doe@example.com"
              aria-label="john.doe@example.com"
              aria-describedby="navbar-invite-email2"
              name="user-email"
              [(ngModel)]="inviteContent['email']"
              [class.error]="newInviteForm.submitted && FEmailRef.invalid"
              #FEmailRef="ngModel"
              tabindex="3"
              required
            />
            <span *ngIf="newInviteForm.submitted && FEmailRef.invalid" class="invalid-form">
              <small class="form-text text-danger" *ngIf="FEmailRef.errors.required">This field is required!</small>
            </span>
            <small class="form-text text-muted"> You can use a valid email. </small>
          </div>
          <!-- / Email -->
  
          <!-- Phone -->
          <div class="form-group col-6">
            <label class="form-label" for="navbar-invite-phone">Phone</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">+61</span>
              </div>
              <input 
              type="text" 
              id="navbar-invite-phone"
              class="form-control" 
              placeholder="0000 000 000"
              mask="9000 000 000" 
              aria-describedby="navbar-invite-phone2"
              name="user-phone"
              [(ngModel)]="inviteContent['phoneNumber']"
              [class.error]="newInviteForm.submitted && FphoneNumberRef.invalid"
              #FphoneNumberRef="ngModel"
              tabindex="4"
              required
            />
            </div>
            <span *ngIf="newInviteForm.submitted && FphoneNumberRef.invalid" class="invalid-form">
              <small class="form-text text-danger" *ngIf="FphoneNumberRef.errors.required">This field is required!</small>
            </span>
            <small class="form-text text-muted"> You can only use numbers </small>                    
          </div>    
          <!-- / Phone -->
  
          <!-- Birthday -->
  
          <div class="form-group col-6">
            <label class="form-label" for="navbar-invite-birthday">Birthday</label>
            <input
              type="date"
              class="form-control"
              aria-describedby="navbar-invite-birthday"
              id="birthday"
              name="birthday"
              max="{{ today }}"
              value="{{ today }}"
              [(ngModel)]="inviteContent['birthday']"
              tabindex="5"
              required
            />
          </div>
          <!-- / Birthday -->
  
          <div class="form-group col-6">
            <label class="form-label" for="navbar-invite-gender">Gender</label>
            <select 
              class="form-control" 
              [(ngModel)]="inviteContent['gender']"
              tabindex="6"
              name="gender"
              id="gender0"
              required
              [ngClass]="{ 'is-invalid': submitted && f.gender.errors }"
              >
              <option value=""></option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>       
            <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
              <div *ngIf="f.gender.errors.required">Gender is required</div>
            </div>
  
          </div>          
          <div class="form-group col-12">
            <!-- GOOGLE MAPS -->
            <label class="form-label" for="navbar-invite-address">Address</label>
  
              <input
                type="text"
                #addresstext
                [(ngModel)]="inviteContent['address']"
                class="form-control"
                placeholder="15 Jonh St, Southport, Gold Coast, QLD, Australia"
                name="user-address"
                required
                tabindex="7"
                [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
              />  
          </div>
  
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [disabled]="newInviteForm.invalid" (click)="modal.close('Submit')" (click)="createMember()" tabindex="8" rippleEffect>
        Submit
      </button>
    </div>
  </ng-template>
  <!-- / Modal -->
